import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Menu from "../components/menu";
import "../scss/subpage.scss"

class Subpage extends React.Component {
    constructor(props) {
        super(props);
        this.handleMenuClick = this.handleMenuClick.bind(this)
        const data = require("../data/api.json").data  
        this.state = {
            menuStatus: false,
            menu: data.menuItems,
        }
    }

    handleMenuClick() {
        this.setState({
            menuStatus: !this.state.menuStatus
        })
    }

    addCollpase() {
        if(typeof document !== "undefined") {
            const coll = document.getElementsByClassName("top");
            for (let i = 0; i < coll.length; i++) {
                coll[i].removeEventListener("click",()=>{})
                var newElement = coll[i].cloneNode(true);
                coll[i].parentNode.replaceChild(newElement, coll[i]);

                coll[i].addEventListener("click", function() {

                    const openedElement = document.querySelectorAll(".open")[0];
                    if(openedElement) {
                        openedElement.querySelector(".body").style.maxHeight = null;
                        openedElement.querySelector(".top").classList.remove("active");    
                        openedElement.classList.remove("open");    
                    }

                    if(openedElement !== this.parentElement) {
                        this.parentElement.classList.toggle("open");
                        this.classList.toggle("active");
                        var content = this.nextElementSibling;
                        if (content.style.maxHeight){
                            content.style.maxHeight = null;
                        } else {
                            content.style.maxHeight = content.scrollHeight + "px";
                        } 
                    } 
                });
            }
        }
    }

    render() {
        setTimeout(() => {
            this.addCollpase();
        }, 100);
        const { text, title } = this.props.pageContext
        
        return (<>
            <Header handleMenuClick={this.handleMenuClick} dark={true} menuStatus={this.state.menuStatus} />
            <Menu menu={this.state.menu} menuStatus={this.state.menuStatus} handleMenuClick={this.handleMenuClick}/>
            <div className={"subpage " + title.split(' &').join("").split(" ").join("-")}>
                { title !== "News" && title !== "Press & Media"? 
                    <div className="content">
                        <h2 className="title" dangerouslySetInnerHTML={{__html: title}}></h2>
                    </div>
                : ""}
                <div className="body" dangerouslySetInnerHTML={{__html: text}} />
            </div>
            <Footer />
        </>)
    }
}

export default Subpage;